@import "../../../sass/variables.scss";

.product-card {
  margin-bottom: 2rem;

  a {
    position: relative;
    display: block;
    transition: 250ms;
    top: 0;
    left: 0;
    width: 100%;

    &:hover {
      color: inherit;

      figure {
        border-color: $red;
      }
    }
  }

  figure {
    padding: 1rem 0;
    text-align: center;
    border: solid 1px #dfdfdf;
    background-color: white;
    position: relative;
    img {
      max-width: 80%;
      transition: 100ms;
    }
  }

  h3 {
    margin: 0 0 0.5rem 0;
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
  }

  .price {
    margin-bottom: 1.5rem;
    padding: 0;
    display: inline-block;
    text-align: center;
    color: $red;
    transition: 200ms;
    font-weight: 600;
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 768px) {
  .product-card {
    .price {
      font-size: 1.5rem;
    }
  }
}
