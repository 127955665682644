.imageLoader {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: relative;
  top: 0;
  left: 0;

  .imageSpinner {
    width: 48px;
    height: 48px;
    border: 3px solid #333;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
  }
  .imageSpinner::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-bottom-color: #ff3d00;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
