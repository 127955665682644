header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

.fixed {
  .infobar {
    display: none;
  }

  .navigation {
    animation-name: navAnimation;
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    top: -80px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  @keyframes navAnimation {
    from {
      top: -80px;
    }
    to {
      top: 0;
    }
  }
}
