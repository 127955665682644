.navigation-content-container {
  display: flex;
  align-items: stretch;
  height: auto;
}

@media screen and (min-width: 768px) {
  .navigation-content-container {
    height: 100%;
  }
}
