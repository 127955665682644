@import '../../../sass/variables.scss';

.promobox-secondary-container {
  max-width: 1400px;
  margin: auto;
  padding: 2rem 0;

  &__border {
    border-bottom: solid 1px #cfcfcf;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  h2 {
    position: relative;
    margin: 0 0 2rem 0;
    font-family: "Teko-Semibold", sans-serif;
    font-size: 2rem;
    text-transform: uppercase;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: $red;
    }

  }

  .product-card {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .promobox-secondary-container {
    padding: 4rem 0;

    h2 {
      margin: 0 0 2.5rem 0;
      font-size: 2.5rem;
    }
  }
}
