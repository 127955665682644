@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&subset=latin-ext");
@import "~bootstrap/scss/bootstrap";

body {
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  padding-top: 65px !important;
}

/*.container {
  max-width: 1920px;
  padding: 0;
}*/

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #f2060a;
  }
}

.h2 {
  margin: 0;
  padding: 2rem 0;
  font-family: "Teko-Semibold", sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  text-align: center;
}

.btn {
  &.btn-primary,
  &.btn-secondary {
    font-size: 1rem;
    padding: 12px;
    background: #e50000;
    background: -webkit-linear-gradient(left, #e50000 0%, #cd131e 100%);
    border-radius: 24px;
    text-transform: capitalize;
    font-weight: 500;
    color: white;
    border: none;
    transition: 250ms;
    width: 100%;

    &:hover {
      opacity: 0.9;

      &:disabled {
        opacity: 0.65;
      }
    }

    &:focus,
    &:active {
      background: #e50000;
      background: -webkit-linear-gradient(left, #e50000 0%, #cd131e 100%);
      border: none;
      box-shadow: none !important;
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.65;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  &.btn-secondary {
    margin-top: 1rem;
    background-color: #999999;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.show-mobile {
  @media all and (min-width: 992px) {
    display: none !important;
  }
}

.show-desktop {
  display: none;
  @media all and (min-width: 992px) {
    display: block;
  }
}

@media all and (min-width: 992px) {
  body {
    padding-top: 112px !important;
  }
}
