.nuka-slide-container {
  a {
    display: block;
    width: 100% !important;
    flex: 0 0 100% !important;
  }

  a,
  img {
    max-width: 100%;
  }
}
