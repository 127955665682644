@import '../../../sass/variables';

// Mixins
@import '../../../sass/mixins';

.cookie-accept {
  z-index: 9999;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  @include box-shadow(0,0,15px,rgba(0,0,0,0.1));
  background-color: $white;

  &.hidden {
    display: none;
  }

  button {
    background-color: $red;
    color: $white;
    padding: 0.35rem 2rem;
    border: none;
    cursor: pointer;
  }

  a {
    display: inline-block;
    margin-left: 1rem;
    text-decoration: underline;

    &:hover, &:active {
      color: inherit;
      text-decoration: none;
    }
  }

  .action {
    margin-bottom: 0;
  }
}
