.static-content {
  &__container {

  }

  &__text {
    padding-bottom: 2rem;
    line-height: 1.5;

    h5 {
      padding-bottom: 1rem;
      font-size: 1.25rem;
      font-weight: 700;
    }

    ul, ol {
      li {
        padding-bottom: 12px;
        line-height: 1.8;
      }
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}