.breadcrumb-container {
  .container {
    background: #efefef;
    padding: 4px 16px;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li.breadcrumb__item {
      display: none;
      padding: 5px;
      &:nth-last-of-type(2) {
        display: block;
      }
      &:before {
        margin-right: 0.75rem;
        color: #f2060a;
        content: "\AB";
      }
      &:last-of-type {
        &:after {
          content: "";
        }
      }

      @media all and (min-width: 768px) {
        display: inline;
        &:before {
          display: none;
        }

        &:after {
          border: 2px solid #A3AFB9;
          border-right: none;
          border-bottom: none;
          content: "";
          display: inline-block;
          height: 8px;
          margin: 3px 0 2px 16px;
          transform: translate(0, 0) rotate(135deg);
          width: 8px;
        }

        &:nth-last-of-type(2) {
          display: inline;
        }
      }

      a, button {
        border: none;
        background-color: transparent;
        display: inline-block;
        padding: 0 6px;
        text-transform: capitalize;

        &:hover {
          color: #f2060a;
        }

        &.non-clickable {
          pointer-events: none;
        }
      }
    }
  }
}

@media all and (min-width: 768px) {
  .breadcrumb-container {
    margin-top: 1rem;

    .container {
      background: none;
      padding: 16px 0;
      max-width: 1400px;
    }

    ul {
      display: flex;

      li {
        &:last-of-type {
          & > * {
            color: #000;
          }

          &:after {
            display: none;
          }
        }
      }

      button, a {
        color: #666;
      }
    }
  }
}
