@import "../../sass/variables";
@import "../../sass/mixins";

.checkout-container {
  padding-top: 2rem;
  padding-bottom: 4rem;
  max-width: 1440px !important;

  h2 {
    font-size: 2rem;
    text-align: center;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .box {
    background-color: white;
    border: solid 1px #dfdfdf;
    padding: 1.25rem;
    margin-bottom: 2rem;
    color: #444444;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding-bottom: 8px;
  }

  h4 {
    border-bottom: solid 1px #efefef;
    padding-bottom: 8px;
  }

  .form-check {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .form-check-label.delivery {
    img {
      height: 26px;
      margin-left: 8px;
    }
  }

  .btn {
    &.btn-primary {
      &__small {
        width: auto;
        padding-left: 4rem;
        padding-right: 4rem;
      }
    }
  }

  input {
    padding: 1.25rem;
    border-radius: 0;
    border: solid 1px #dfdfdf;
  }

  .disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  .validation_error {
    color: $red;
    font-size: 12px;
  }

  .modify-success {
    margin-top: 1rem;
    color: #00a92c;
    font-weight: 700;
  }

  .modify-error {
    margin-top: 1rem;
    color: #f2060a;
    font-weight: 700;
  }

  .save-modification {
    display: inline-block;
    line-height: 3;
    color: $red-secondary;
    font-size: 0.75rem;
    font-weight: 700;
    vertical-align: top;
    padding-left: 1rem;
    margin-bottom: 0;

    &__success {
      color: #00a92c;
    }
  }

  a.accept-terms-link {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  textarea {
    height: 100px;
    resize: none;
  }

  .order-details-container {
    background-color: #f7f7f7;
    border: solid 1px #dfdfdf;
    height: 100%;
    padding: 1.5rem 1rem;

    h3 {
      font-weight: 600;
    }

    label {
      font-size: 13px;
    }
  }

  .order-details {
    h3 {
      &:before {
        display: none;
      }
    }

    h4 {
      font-size: 1rem;
      font-weight: 700;
      border-bottom: none;
      color: $red-secondary;
    }

    table {
      background-color: white;
      thead {
        th {
          border-bottom: none;
          border-top: none;
          background-color: #222;
          color: white;
          font-weight: 500;
          font-size: 14px;
        }
      }

      tbody {
        td {
          font-size: 14px;
          vertical-align: middle;

          &:last-of-type {
            white-space: nowrap;
          }
        }
      }
    }
  }

  .order-details-table {
    overflow-x: auto;
  }

  .predictive-country-list {
    z-index: 2;
    position: absolute;
    top: 72px;
    left: 5px;
    width: 300px;
    background-color: $white;
    @include box-shadow(0, 0, 5px, rgba(0, 0, 0, 0.15));

    a {
      display: block;
      padding: 8px 10px;
      cursor: pointer;
      font-size: 12px;
      @include transition(250ms);

      &:hover {
        background-color: #dfdfdf;
      }
    }
  }

  .selected-parcel-point-details {
    display: flex;
    align-items: center;
    justify-content: space-between;

    figure {
      margin: 0;
      img {
        max-height: 60px;
      }
    }
  }
}
.unsupported-shipping-country-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  height: 100%;
  background-color: rgba(50, 50, 50, 0.5);

  .modal-content {
    position: relative;
    top: 50%;
    margin-top: -109px;
    margin-right: auto;
    margin-left: auto;
    padding: 2rem;
    width: 100%;
    max-width: 700px;
    background-color: white;
    text-align: center;
    @include box-shadow(0, 0, 70px, rgba(0, 0, 0, 0.25));

    p {
      text-align: left;
    }

    a {
      display: inline-block;
      color: white;
      padding: 0.75rem 1rem;
      text-align: center;
      margin-right: 0.5rem;
      border: none;
      text-transform: uppercase;
      font-weight: 500;

      &.text {
        display: inline;
        text-transform: none;
        color: $red-secondary;
        text-decoration: underline;
        margin: 0;
        padding: 0;

        &:hover {
          text-decoration: none;
        }
      }

      &.red-btn {
        background-color: $red;
        display: block;
        margin-bottom: 0;
      }

      &.gray-btn {
        background-color: #999999;
        display: block;
        margin-bottom: 1rem;
      }

      &.btn-text {
        background-color: transparent;
        color: #0a0a0a;
        text-decoration: underline;
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .checkout-container {
    h2 {
      font-size: 2.5rem;
      text-align: left;
    }

    .is-sticky {
      position: sticky;
      animation-name: stickyAnimation;
      animation-duration: 700ms;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
      top: 0;
      z-index: 999;
    }
  }
}

@keyframes stickyAnimation {
  from {
    top: 0px;
  }
  to {
    top: 110px;
  }
}
