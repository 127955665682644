@import url("../../../sass/variables.scss");

.infobar {
  display: none;
  background-color: #222;
  color: #fff;
  height: 32px;
}

.infobar-links {
  display: flex;
  align-items: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  height: 100%;

  li {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &.active {
      a {
        border-top: solid 2px red;
        background-color: #afafaf;
      }
    }

    a {
      border-top: solid 2px transparent;
      display: block;
      font-size: 12px;
      padding: 6px 16px;
      transition: 200ms;
      height: 100%;

      &:hover {
        color: #f2060a;
      }
    }
  }
}

.infobar-authentication {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: "Roboto", sans-serif;

  li {
    display: flex;
    align-items: center;
    padding-right: 16px;
    a {
      align-items: center;
      display: flex;
      font-size: 12px;
      height: 100%;
      padding: 7px 0;
      text-transform: uppercase;
    }

    &:first-of-type {
      a {
        &::after {
          content: "";
          display: inline-block;
          width: 1px;
          height: 12px;
          background-color: #999;
          margin-left: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .infobar {
    display: flex;
    justify-content: space-between;
  }
}
