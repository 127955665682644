@import "../../../sass/variables";
@import "../../../sass/mixins";

.register-background {
  padding-top: 0 !important;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-container,
.club-register-container,
.register-container {
  max-width: 600px !important;
  margin: 0 auto;
  padding: 2rem;
  box-shadow: none;
  background-color: white;

  .h2 {
    margin: 0;
    padding: 1rem 0;
    font-family: "Teko-Semibold";
    font-size: 2.5rem;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .register-description {
    margin-bottom: 1rem;
    font-size: 0.875rem;
    text-align: center;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }

  h3 {
    margin: 0;
    padding: 1rem 0;
    font-family: "Teko-Semibold";
    font-size: 2rem;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .social-container {
    text-align: center;
    img {
      display: block;
      margin: 1rem auto;
      max-width: 300px;
    }

    .btn-custom-facebook {
      width: 300px;
      height: 40px;
      border: none;
      background-color: #1877f2;
      color: $white;
      font-size: 16px;
      cursor: pointer;
      @include border-radius(5px);
      &:before {
        float: left;
        display: block;
        content: "";
        width: 24px;
        height: 24px;
        background-image: url("https://static.xx.fbcdn.net/rsrc.php/v3/yN/r/szGrb_tkxMW.png");
        margin-left: 6px;
      }
      &:focus {
        border: none;
        outline: none;
      }
    }

    .g-signin2 {
      margin-top: 1rem;
      box-shadow: none !important;
      > .abcRioButton {
        width: 300px !important;
        margin: auto;
        height: 40px !important;

        > .abcRioButtonContentWrapper {
          background-color: $white;
          @include border-radius(5px);

          > .abcRioButtonIcon {
            padding: 11px !important;
            border-right: solid 2px #efefef;
          }

          > .abcRioButtonContents {
            font-size: 16px !important;
            line-height: 40px !important;
          }
        }
      }
    }
  }

  .separator {
    position: relative;
    margin: 20px 0;
    font-size: 1rem;
    text-align: center;
    p {
      z-index: 3;
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0 12px;
      background-color: white;
      text-transform: uppercase;
    }
    span {
      display: inline-block;
      width: 100%;
      height: 1px;
      position: absolute;
      top: 12px;
      left: 0;
      background-color: #999999;
    }
  }

  input[type="text"],
  input[type="password"],
  select {
    padding: 8px;
    border-radius: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
    border: solid 1px #cfcfcf;
    height: 41px !important;
    &:focus {
      outline: none;
      @include box-shadow(0, 0, 0);
      border: solid 1px #0a0a0a;
    }
    &::placeholder {
      color: #dfdfdf;
    }
  }

  label {
    span {
      color: $red;
    }
  }

  button,
  a {
    &.btn-primary {
      border: none;
    }
  }

  .lost-password-container {
    padding: 0 0 0.5rem 0;

    a {
      &.lost-password {
        margin-top: 10px;
        font-size: 12px;
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .login-lead {
    font-size: 14px;
  }

  .register-link-container {
    font-size: 12px;

    a {
      font-size: 12px;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .existing-account {
    margin-top: 1rem;
    font-size: 12px;
  }

  .validation_error {
    color: $red;
    font-size: 12px;
    font-weight: 700;
  }

  .login-error-message {
    margin-bottom: 1rem;
    padding: 10px;
    background-color: $red;
    color: $white;
    text-align: center;
  }

  .login-success-message {
    margin-bottom: 1rem;
    padding: 10px;
    background-color: $green;
    color: $white;
    text-align: center;
  }

  .predictive-country-list {
    z-index: 2;
    position: absolute;
    top: 72px;
    left: 5px;
    width: 300px;
    background-color: $white;
    @include box-shadow(0, 0, 5px, rgba(0, 0, 0, 0.15));

    a {
      display: block;
      padding: 8px 10px;
      cursor: pointer;
      font-size: 12px;
      @include transition(250ms);

      &:hover {
        background-color: #dfdfdf;
      }
    }
  }

  .form-validation-error {
    padding-top: 1rem;
    color: $red;
    font-size: 12px;
    font-weight: 700;
  }
}

.submit-container {
  padding: 0.5rem 0 1rem 0;
}

@media only screen and (min-width: 768px) {
  .login-container,
  .club-register-container,
  .register-container {
    max-width: 600px;
    margin: 2rem auto;
  }
}

@media only screen and (min-width: 992px) {
  .register-background {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .login-container,
  .club-register-container,
  .register-container {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.97);
  }
}

.hidden {
  display: none !important;
}
