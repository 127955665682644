@import "../../sass/variables.scss";

.product-list-container {
  margin: 2rem auto;
  max-width: 1400px;

  h2 {
    margin: 2rem 0;
    padding: 0;
    position: relative;
    font-size: 2rem;

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 3px;
      background-color: $red;
    }
  }

  .product-lead {
    font-size: 1.25rem;
    text-align: center;
    margin: 0 0 2rem 0;
  }

  .product-infobar {
    margin-bottom: 1.5rem;
    padding-bottom: 1rem;
    border-bottom: solid 1px #dfdfdf;
  }

  .product-list-numbers {
    font-size: 0.875rem;
    line-height: 3;
    text-align: center;
  }

  .filter-container {
    text-align: center;
    select {
      padding: 8px 40px;
      border: solid 1px #afafaf;
      text-align: center;
    }
  }

  .product-card {
    margin-bottom: 2rem;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (min-width: 768px) {
  .product-list-container {
    h2 {
      font-size: 2.5rem;
    }

    .product-list-numbers {
      float: left;
    }

    .filter-container {
      float: right;
      max-width: 250px;
    }
  }
}
