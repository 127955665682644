.order-container {
  padding-top: 2rem;
  padding-bottom: 4rem;

  .btn {
    margin: 2rem auto 0 auto;
    display: block;
    padding: 1rem;
    width: 100%;
    max-width: 300px;
    border: none;
    background: #e0060a;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
  }

}