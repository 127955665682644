.gm-style-iw-c {
  max-width: 350px !important;
  padding: 16px !important;

  h4 {
    font-size: 16px;
    padding: 8px 0 16px 0;
  }

  .btn-small {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .gm-style-iw-chr {
    position: absolute;
    top: 8px;
    right: 0;
  }
}

.gm-style-iw-d {
  overflow: auto !important;

  p {
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 4px;
    }

    .btn-small {
      font-size: 14px;
      padding: 8px;
    }
  }
}

.gm-style-iw-c,
.gm-style-iw-tc {
  margin-top: -36px;
}

.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}
