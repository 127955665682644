.indicator {
  position: absolute;
  bottom: -4px;
  left: 2px;
  width: 14px;
  height: 14px;
  background-color: yellow;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 600;
  text-align: center;
}
