@import "../../sass/variables.scss";

.basket-details {
  &__mobile {
    margin-bottom: 4rem;
    img {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .price {
      color: $red;
      font-weight: 700;
      font-size: 1.5rem;
    }

    .basket-item {
      margin-bottom: 1.5rem;
    }
  }

  &__container {
    padding: 0 0 2rem 0;

    .h2 {
      font-size: 2.5rem;
      padding: 1.5rem 0 1rem 0;
    }

    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .btn-remove-basket-item {
      background: none;
      border: none;
      width: 26px;
      height: 40px;

      &:focus,
      &:active {
        outline: none;
      }
    }

    .products-headline {
      margin-bottom: 1.25rem;
      font-size: 1.25rem;
      font-weight: 700;
      text-align: center;

      @media all and (min-width: 768px) {
        .products-headline {
          text-align: left;
          margin-bottom: 0;
        }
      }
    }

    .checkout-box {
      padding: 1rem 0;
      border: 1px solid #d4dee7;
      padding: 28px 16px;
      border-radius: 12px;

      h3 {
        padding: 0 1rem 6px 1rem;
        font-size: 1.5rem;
        font-weight: 600;
      }

      h4 {
        align-items: center;
        display: flex;
        font-size: 1rem;
        font-weight: 500;
        justify-content: space-between;
        margin-bottom: 1.25rem;
        padding: 1rem;

        span {
          font-weight: 600;
          font-size: 22px;
        }
      }

      p {
        margin: 12px 0 0 0;
        font-size: 13px;
      }

      .btn-primary {
        background: $red;
        background: -webkit-linear-gradient(left, $red 0%, $red-secondary 100%);
        padding: 12px 0;
        font-size: 16px;
      }

      .btn-secondary {
        background-color: transparent;
        background-image: none;
        border: solid 1px $red-secondary;
        color: $red-secondary;
        font-size: 16px;
        padding: 11px 0;
      }

      .link {
        display: block;
        text-align: center;
        font-size: 0.875rem;
        padding: 1rem 0 0 0;
      }
    }
  }

  &__table {
    border: 0;
    margin-bottom: 1rem;
    background-color: white;
    border: 1px solid #d4dee7;
    padding: 16px;
    border-radius: 12px;

    thead {
      th {
        border-top: none;
        text-align: center;

        &:first-of-type {
          text-align: left;
        }
      }
    }

    tbody {
      td {
        padding: 1.25rem 1rem;
        vertical-align: middle;

        &:first-of-type {
          width: 125px;
        }

        &.product-detail {
          min-width: 320px;
        }

        input {
          margin-left: 0.75rem;
          display: inline-block;
          width: 80px;
          padding: 1rem;
          font-size: 1rem;
          border-radius: 0;
          text-align: center;
          -moz-appearance: textfield;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }
    }
    img {
      height: 150px;
    }
  }
}

@media all and (min-width: 768px) {
  .basket-details {
    &__container {
      max-width: 1400px;
      padding: 2rem 0 4rem 0;

      .h2 {
        padding: 0;
        font-size: 2.5rem;
      }
    }
  }
}

@media all and (min-width: 992px) {
  .basket-details {
    &__container {
      max-width: 1400px !important;
      padding: 2rem 0 4rem 0;

      .h2 {
        padding: 2rem 0;
        font-size: 3rem;
      }
    }
  }
}
