@import "../../../sass/variables.scss";

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 65px;
  background: #e0060a;
  background: -webkit-linear-gradient(left, $red 0%, $red-secondary 100%);

  &__left {
    display: flex;
    align-items: stretch;
    height: 100%;
  }

  .show-desktop {
    display: none;

    @media all and (min-width: 1200px) {
      display: flex;
      align-items: center;
    }
  }

  .honvedfc-logo {
    align-items: center;
    display: flex;
    width: fit-content;
    margin: 0 16px;
    img {
      margin-right: 16px;
      width: 50px;
      max-width: 100%;
      max-height: 50px;
    }
    h1 {
      line-height: 1;
    }
  }

  .search {
    z-index: 2;
  }

  .profile,
  .cart {
    padding: 0 6px;
  }

  .cart {
    padding-right: 0;
    position: relative;
  }

  .search-container {
    visibility: hidden;
    position: relative;
    left: 36px;

    input {
      background-color: #efefef;
      border: solid 1px #222;
      border-radius: 18px;
      padding: 6px 36px 6px 12px;
      font-size: 14px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    &__visible {
      visibility: visible;
    }
  }

  @media all and (min-width: 1200px) {
    /*background-position-x: 150%;
    background-image: url("https://depo.honvedfc.hu/web/images/shop/header-symbol.png");*/
    background: #e0060a;
    background: -webkit-linear-gradient(left, $red 0%, $red-secondary 100%);
  }

  /*@media all and (min-width: 1280px) {
    background-position-x: 124%;
  }

  @media all and (min-width: 1440px) {
    background-position-x: right;
  }*/

  .title {
    h1 {
      line-height: 1;
      font-size: 1.5rem;
      color: #060405;
      font-family: "Teko-Semibold", sans-serif;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .menu {
    display: none;
    ul {
      display: flex;
      align-items: stretch;
      margin-bottom: 0;
      list-style: none;
      li {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
          background-color: #f0f0f0;
          & > ul {
            display: block;
            position: absolute;
            top: 100%;
            z-index: 3;

            li {
              background-color: #f0f0f0;
              width: 200px;

              a {
                font-family: "Roboto", sans-serif;
                font-size: 14px;
                font-weight: 600;
                line-height: 1;
                padding: 16px 18px;
                transition: 250ms;
                width: 100%;

                &:hover {
                  background-color: #dfdfdf;
                  text-indent: 5px;
                }
              }
            }
          }
        }

        a,
        button {
          background-color: transparent;
          border: none;
          padding: 0 22px;
          height: auto;
          font-family: "Teko-Semibold", sans-serif;
          display: block;
          font-size: 22px;
          text-transform: uppercase;
          color: #060405;

          &:hover {
            color: inherit;
            background-color: #f0f0f0;
          }
        }

        button {
          height: 100%;
        }

        ul {
          display: none;
          padding-left: 0;
        }
      }
    }
  }

  .navigation-functions {
    display: none;
    align-items: center;
    margin-right: 32px;
    button {
      background-color: transparent;
      border: none;
    }

    @media all and (min-width: 1200px) {
      display: flex;
    }
  }

  .shopping-cart {
    position: absolute;
    top: 18px;
    right: 44px;

    img {
      height: 40px;
    }
  }

  @media all and (min-width: 768px) {
    height: 80px;

    .honvedfc-logo {
      width: 250px;
      margin: 0 16px 0 32px;
      img {
        width: 70px;
        max-height: 70px !important;
      }
    }

    .title {
      h1 {
        font-size: 30px;
        line-height: 1;
        padding-top: 6px;
      }
    }
  }

  @media all and (min-width: 1200px) {
    .menu {
      display: flex;
    }

    .honvedfc-logo {
      width: 70px;
    }

    .title {
      h1 {
        font-size: 40px;
      }
    }
  }
}
