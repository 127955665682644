@import "../../../sass/variables";
@import "../../../sass/mixins";

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.product-sheet-container {
  margin: 1.5rem auto 2rem auto;
  display: flex;
  flex-direction: column-reverse;

  .h2 {
    font-size: 2rem;
    padding-top: 0;
    padding-bottom: 16px;
    text-transform: none;
    text-align: center;
    text-transform: uppercase;
  }

  .h3 {
    text-align: center;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .product-item-number {
    text-align: center;
    padding-bottom: 24px;
  }

  .product-thumbs {
    display: none;
  }

  .product-image {
    text-align: center;

    &__item {
      display: block;
    }
    img {
      margin: 15px auto 0 auto;
      max-width: 100%;
    }
  }

  .shipping-details {
    margin: 16px 0 0 0;
    font-size: 14px;
  }

  .error-msg {
    color: $red;
    font-weight: bold;
  }

  .product-details {
    padding: 1.5rem 0;
    border: solid 1px #ddd;
    background-color: #f8f8f8;

    .h3 {
      padding: 0 0 1rem 0;
      font-size: 1.5rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      font-size: 0.875rem;
    }

    .size-header-choose {
      float: left;
    }

    .size-table {
      float: right;
    }

    .size-cube-container {
      display: inline-flex;
      flex-wrap: wrap;
    }

    .size-cube {
      margin-right: 4px;
      margin-bottom: 10px;
      width: calc(25% - 4px);
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      background-color: #999999;
      text-align: center;
      color: white;
      cursor: pointer;
      transition: 200ms;

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:hover {
        background-color: #0a0a0a;
      }

      &.active {
        background-color: #0a0a0a;
      }
    }

    .select-quantity-label {
      padding-top: 12px;
      font-size: 0.875rem;
    }

    .select-quantity-form {
      display: inline-block;
      width: 100px;
      padding: 2rem;
      font-size: 2rem;
      border-radius: 0;
      text-align: center;
      -webkit-appearance: none;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    .quantity-modifier {
      margin: 0;
      background: transparent;
      border: none;
      font-size: 2rem;
      font-weight: bold;
    }

    .total-price {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1.25rem;
      font-weight: 600;

      .big-price {
        font-family: "Teko-Semibold", sans-serif;
        font-size: 3rem;
        color: $red;
        line-height: 1.25;
      }
    }

    .btn-basket {
      border-radius: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px;
      width: 100%;
      border: none;
      background: $red;
      background: -webkit-linear-gradient(left, $red 0%, $red-secondary 100%);
      text-transform: capitalize;
      font-weight: 500;
      color: white;

      img {
        margin-right: 16px;
        max-height: 20px;
      }
    }
  }

  .product-details-container {
    padding: 8px 16px;
    border-radius: 6px;

    h3 {
      margin-bottom: 16px;
      font-size: 22px;
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      font-size: 0.875rem;
      line-height: 1.6;
    }
  }

  p.out-of-stock {
    margin: 2rem 0 0 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $red;
  }
}

.basket-response {
  display: none;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  padding: 6rem 2rem 2rem 2rem;
  width: 100%;
  height: 100%;
  background-color: white;
  text-align: center;
  @include box-shadow(0, 0, 70px, rgba(0, 0, 0, 0.25));

  &.show {
    display: block;
  }

  a {
    display: inline-block;
    color: white;
    padding: 0.75rem 1rem;
    text-align: center;
    margin-right: 0.5rem;
    border: none;
    text-transform: uppercase;
    font-weight: 500;

    &.red-btn {
      background-color: $red;
      display: block;
      margin-bottom: 1rem;
    }

    &.gray-btn {
      background-color: #999999;
      display: block;
      margin-bottom: 1rem;
    }

    &.btn-text {
      background-color: transparent;
      color: #0a0a0a;
      text-decoration: underline;
      text-transform: none;
      font-weight: normal;
    }
  }
}

@media all and (min-width: 768px) {
  .product-sheet-container {
    padding: 0 2rem;

    .h2 {
      font-size: 2.5rem;
      padding-bottom: 8px;
      text-transform: none;
      text-align: left;
      text-transform: uppercase;
    }

    .product-image {
      text-align: center;
      img {
        margin: 15px auto 2rem auto;
        max-width: 60%;
      }
    }

    .product-details {
      margin-top: 0;
      padding: 2rem 1rem;
      border: solid 1px #ddd;
      background-color: #f8f8f8;

      h3 {
        text-align: left;
      }

      .size-cube {
        width: 12%;
        padding-top: 1.2rem;
        padding-bottom: 1.2rem;

        &:nth-child(4n) {
          margin-right: 4px;
        }
      }

      .total-price {
        flex-direction: row;
        align-items: center;
        gap: 16px;
      }
    }

    .product-item-number {
      text-align: left;
    }

    .product-details-container {
      padding: 8px 0;
    }

    .product-description-container {
      margin-top: 48px;
      border: 1px solid #d4dee7;
      padding: 32px;
      border-radius: 6px;

      h3 {
        margin-bottom: 16px;
        font-size: 22px;
        font-weight: 600;
      }

      p {
        margin-bottom: 0;
        font-size: 0.875rem;
        line-height: 1.6;
      }
    }
  }

  .basket-response {
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -350px;
    height: auto;
    padding: 2rem;
    width: 100%;
    max-width: 700px;
    background-color: white;
    text-align: center;
    @include box-shadow(0, 0, 52px, rgba(0, 0, 0, 0.25));

    &.show {
      display: block;
    }

    a {
      display: inline-block;
      color: white;
      padding: 0.75rem 1rem;
      text-align: center;
      margin-right: 0.5rem;
      border: none;

      &.red-btn {
        background: $red;
        background: -webkit-linear-gradient(left, $red 0%, $red-secondary 100%);
        display: inline-block;
        margin-bottom: 0;
      }

      &.gray-btn {
        background-color: #999999;
        display: inline-block;
        margin-bottom: 0;
      }

      &.btn-text {
        background-color: transparent;
        color: #0a0a0a;
        text-decoration: underline;
        text-transform: none;
        font-weight: normal;
      }
    }
  }
}

@media all and (min-width: 992px) {
  .product-sheet-container {
    flex-direction: row;
    align-items: flex-start;
    max-width: 1400px;
    column-gap: 32px;
    padding: 0;

    .h2 {
      font-size: 2.5rem;
      text-transform: uppercase;
      border: none;
      padding-top: 0;
    }

    .h3 {
      text-align: left;
    }

    .product-thumbs {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    .product-thumbs__item {
      border: 1px solid #d4dee7;
      border-radius: 6px;
      width: 100px;
      background-color: transparent;

      &.active {
        border-color: red;
      }

      img {
        max-width: 100%;
      }
    }

    .btn-pager {
      &__prev {
        img {
          opacity: 0.4;
          width: 60px;
        }
        margin-right: 0.5rem;
      }

      &__next {
        img {
          @include rotate(180);
          opacity: 0.4;
          width: 60px;
        }
      }
    }

    .product-image-container {
      width: 60%;
    }

    .product-details-container {
      margin-top: 0;
      padding: 0;
      border-radius: 6px;
      width: 40%;
    }

    .product-image {
      text-align: center;

      &__item {
        display: none;
        img {
          opacity: 0;
        }
        &.active {
          display: block;
          img {
            animation: fadein 500ms ease-in;
            animation-fill-mode: forwards;
          }
        }
      }
      img {
        margin: 15px auto 0 auto;
        max-width: 100%;
      }
    }
  }
}
