footer {
  background-color: #f8f8f8;
  border-top: solid 1px #ddd;
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .footer-content {
    padding: 48px 0;
    color: #1a1d21;
    font-size: 13px;

    h5 {
      padding-bottom: 12px;
      font-size: 1.25rem;
      font-weight: 600;
    }

    ul {
      font-size: 13px;
      list-style: none;
      padding-left: 0;

      li {
        padding-bottom: 8px;
      }
    }
  }

  .footer__social-container {
    margin-bottom: 24px;
  }

  .footer__social {
    background-color: #3f3f3f;
    border-radius: 50%;
    display: inline-block;
    height: 40px;
    margin-right: 8px;
    width: 40px;
    text-align: center;

    &:hover {
      background-color: #d22f39;
    }

    img {
      margin-top: 9px;
      max-height: 22px;
      max-width: 22px;
    }

    .newsletter-subscribe {
      display: none;
      float: left;
      span {
        font-weight: bold;
        margin-right: 1rem;
      }

      input {
        padding: 5px 10px;
        border: none;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }

      button {
        padding: 5px 10px;
        border: none;
        background-color: #0a0a0a;
        color: white;
        font-weight: 300;
      }
    }

    a {
      margin: 2px 2rem 0 0;
      float: right;
      img {
        height: 28px;
      }
      &:first-of-type {
        margin-right: 5px;
      }
    }
  }

  .payment-partner {
    margin-bottom: 16px;
    max-width: 120px;
  }

  .delivery-partners-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .delivery-partner {
      max-height: 46px;
    }
  }

  .accepted-cards {
    max-width: 100%;
  }

  .disclaimer {
    padding: 16px 24px;
    background-color: #f8f8f8;
    border-top: solid 1px #ddd;

    font-size: 13px;
    color: #1a1d21;

    &__content {
      display: flex;
      flex-direction: column;
    }

    &__copyright {
      text-align: center;
    }

    &__links {
      margin: 1rem 0 0 0;
      text-align: center;

      a {
        display: block;
        padding-bottom: 6px;
        width: 100%;
      }
    }

    &__payment {
      text-align: center;

      span {
        padding: 10px;
        display: inline-block;
        background-color: white;
        -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
      }
    }
  }
}

@media screen and (min-width: 992px) {
  footer {
    .container {
      max-width: 1400px;
    }

    .disclaimer {
      &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      &__links {
        margin: 0;
        a {
          display: inline;
          padding: 0 10px;
          border-right: solid 1px #666666;

          &:last-of-type {
            border: none;
          }
        }
      }
      &__copyright {
        padding-right: 10px;
      }
      &__payment {
        margin-left: auto;
      }
    }
  }
}
