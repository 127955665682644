// Body
$body-bg: #ffffff;

// Typography
$line-height-base: 1.6;

$header-gray: #d5d5d5;
$red-background: #f2060a;
$primary-font-color: #323131;
$warning-background-color: #2c2722;
$light-text-color: #fdfbfb;
$white: #ffffff;

$black: #050505;
$blue: #3490dc;
$text-gray: #333333;
$indigo: #6574cd;
$pink: #f66D9b;
$red: #e50000;
$red-secondary: #cd131e;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$text-gray: #212529;
